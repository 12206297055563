import React from 'react'

const paneellengte = 1.65;
const paneelbreedte = 0.99;

const paneelMargeHorizontaal = 0.2;
const paneelMargeVerticaal = 0.2;

const paneelWattMin = 250 + 0.2;
const paneelWattMax = 300 + 0.2;
const dakFactor = {
			'N_0': 0.7,
			'N_20': 0.6,
			'N_60': 0.6,
			'NO_0': 0.76,
			'NO_20': 0.7,
			'NO_60': 0.65,
			'NW_0': 0.76,
			'NW_20': 0.7,
			'NW_60': 0.65,
			'O_0': 0.85,
			'O_20': 0.9,
			'O_60': 0.75, 
			'W_0': 0.85,
			'W_20': 0.9,
			'W_60': 0.75,
			'ZO_0': 0.92,
			'ZO_20': 0.95,
			'ZO_60': 0.9,
			'ZW_0': 0.92,
			'ZW_20': 0.95, 
			'ZW_60': 0.9,
			'Z_0': 0.95,
			'Z_20': 1,
			'Z_60': 0.95
		};
const kWhPrice = 0.19;

class StepBereken extends React.Component { 

	calculateAantal() {
		let daklengte = parseInt(this.props.data['daklengte']);
		let dakbreedte = parseInt(this.props.data['dakbreedte']);
		
		
		let fit_horizontal = Math.floor(dakbreedte / (paneelbreedte + paneelMargeHorizontaal));
		let fit_vertical = Math.floor(daklengte / (paneellengte + paneelMargeVerticaal));
		
		var aantal = (fit_horizontal * fit_vertical);
		
		return aantal;
	}
	
	calculateWatt(aantal, wattage) {
		let factorkey = this.props.data['dakrichting'] + '_' + this.props.data['daktype'];
		return Math.round(aantal * wattage * dakFactor[factorkey]);
	}

	calculateEuro(kwh) {
		return Math.round(kwh * kWhPrice);
	}
	
	render() {
		
		var aantal = this.calculateAantal();
		let wattMin = this.calculateWatt(aantal, paneelWattMin);
		let wattMax = this.calculateWatt(aantal, paneelWattMax);
		let euroMin = this.calculateEuro(wattMin);
		let euroMax = this.calculateEuro(wattMax);
	
		return (
			<div className="stepBerekening">
				<p>
					Er is op dit dak ruimte voor<br />
					<strong>{aantal} zonnepanelen</strong>
				</p>
				<p>
					Afhankelijk van het gekozen type zonnepanelen bespaart u per jaar tussen<br />
					<strong>{wattMin} kWh en {wattMax} kWh</strong>
				</p>
				<p>
					Dit levert een jaarlijkse besparing op van tussen<br />
					<strong>€{euroMin},- en  €{euroMax},-</strong>
				</p>
				<p>
					Meld de eigenaar van dit dak aan en wij nemen vrijblijvend contact op!
				</p>
			</div>
		)
	}
}


export default StepBereken
