import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CloseButton = ({ closeToast }) => {

    const handleCloseClick = () => {
        localStorage.setItem('isShowNotification', false);
        closeToast()
    };
    return (
        <button className="Toastify__close-button Toastify__close-button--info" type="button" onClick={handleCloseClick} aria-label="close">
            <svg aria-hidden="true" viewBox="0 0 14 16">
                <path d="M7.71 8.23l3.75 3.75-1.48 1.48-3.75-3.75-3.75 3.75L1 11.98l3.75-3.75L1 4.48 2.48 3l3.75 3.75L9.98 3l1.48 1.48-3.75 3.75z">
                </path>
            </svg>
        </button>
    )
};

class Notification extends Component {
    componentDidMount() {
        this.notify();
    }


    notify() {
        const { type, message } = this.props;
        const today = new Date();

        if (Date.parse(today) < Date.parse(message?.until)) {
            switch (type) {
                case "success":
                    toast.success(message.text);
                    break;
                case "error":
                    toast.error(message.text);
                    break;
                case "info":
                    toast.info(message.text);
                    break;
                default:
                    toast(message.text);
                    break;
            }
        }
    }


    render() {

        return <div>
            <ToastContainer
                position="top-center"
                autoClose={false}
                newestOnTop={false}
                closeOnClick={false}
                closeButton={CloseButton}
                rtl={false}
                pauseOnFocusLoss
                draggable
                theme="colored"
            />
        </div>
    }
}

export default Notification;