import React from 'react'
import Parser from 'html-react-parser';
import loadingGif from '../assets/images/loading.gif'
import demoImage from '../assets/images/demo_installatie.png'
import UserPhotoUpload from './UserPhotoUpload'

const plurallabels = {
	'Omvormer': 'Omvormers',
	'Zonnepaneel': 'Zonnepanelen',
	'Laadpaal': 'Laadpalen',
	'Batterij': 'Batterijen'
}

class InstallatieDetail extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			installatie_selected: 1,
			view_selected: 'overview',
			error: null
		};

	}

	toggleView = () => {

		let newview = (this.state.view_selected === 'overview') ? 'detail' : 'overview';

		this.setState({
			view_selected: newview
		});
	}

	navigate(change) {
		if (change === 0) {
			this.setState({
				view_selected: 'overview',
			});
		}
		this.setState({
			installatie_selected: this.state.installatie_selected + change,
		});
	}

	render() {
		const {isLoading} = this.props;
		let navigation, buttonprevious, buttonnext;
		let producten, product;
		var number;

		if (isLoading) {
			return <LoadingDiv />;
		}

		let installaties = this.props.InstallatieData;

		if (!installaties) {
			return (
				<div>
					<h1>Welkom terug directeurs {this.props.fullname}</h1>
					<p>Er zijn geen installaties gevonden onder dit account. Dit kan zijn omdat dit een nieuw account betreft en de systemen nog geïnstalleerd of aangemeld moeten worden.</p>
					<p>Zouden de installaties er al moeten staan? Neem dan <a href="https://www.zonnefabriek.nl/contact/">contact</a> met ons op.</p>
				</div>
			);
		} else {

			number = Object.values(installaties).length;

			if (this.state.installatie_selected > 1) {
				buttonprevious = <button className="button left" onClick={() => this.navigate(-1)}>&lt;</button>
			}

			if (this.state.installatie_selected < number) {
				buttonnext = <button className="button right" onClick={() => this.navigate(1)}>&gt;</button>
			}

			let data = this.props.InstallatieData;

			producten = Object.keys(data).map(function(key) {
				return 	Object.keys(data[key]).map(function(productkey) {
					return (Array.isArray(data[key][productkey])&& data[key][productkey].find(product => product.productname) )
					 ?
						{data: data[key][productkey], key: productkey}
						: false;
				});
			});

			product = producten[this.state.installatie_selected-1];
			
			if (Array.isArray(product)) {
				product = product.filter(
					function (value) {
						return value
					}
				)
				
				var description = '';
				const descriptionData = [];
				var installatiegarantie_datum = '';	
				for (var i = 0; i < product.length; i++) {
					for (var e = 0; e < product[i].data.length; e++) {
						let productQty = product[i].data[e]['qty'];
						let productCategory = product[i].key;
						let label = productQty === 1 ? productCategory : plurallabels[productCategory];

						const existingItem = descriptionData.find(item => item.key === productCategory);
						if (existingItem) {
							existingItem.qty += productQty;
						} else {
							descriptionData.push({ key: productCategory, label: label?.toLowerCase(), qty:product[i].data[e]['qty'], installation_guarantee_date:product[i].data[e]['installation_guarantee_date'] });
						}
					}
				}
				Object.keys(descriptionData).map(function (key) {
					installatiegarantie_datum = descriptionData[key]['installation_guarantee_date']?.toLowerCase();
					return description += descriptionData[key]['qty'] + ' ' + descriptionData[key]['label']?.toLowerCase() + '<br />';
				});
				description = description.slice(0, -6)
			} else {
				description = (
					`<>
						<h1>Welkom terug directeurs {this.props.fullname}</h1>
						<p>Er zijn (nog) geen installaties gekoppeld aan dit account.</p>
					</>`
				)
			}

			var faq = Object.keys(data).map(function(key) {
				return 	Object.keys(data[key]).map(function(productkey) {
					return (productkey === 'faq') ?
						data[key]['faq']

						: false;
				});
			});

			if (Array.isArray(faq[this.state.installatie_selected-1])) {

				faq = faq[this.state.installatie_selected-1].filter(

					function (value) {
						return value
					}
				)
			} else {
				faq = '';
			}

			var docs = Object.keys(data).map(function(key) {
				return 	Object.keys(data[key]).map(function(productkey) {
					return (productkey === 'documents') ?
						data[key]['documents']

						: false;
				});
			});

			if (Array.isArray(docs[this.state.installatie_selected-1])) {

				docs = docs[this.state.installatie_selected-1].filter(

					function (value) {
						return value
					}
				)
			} else {
				docs = '';
			}
			var installatiegarantie = <div className="installatie">
				<h2>Installatiegarantie</h2>
				Installatiegarantie tot: <strong>{installatiegarantie_datum}</strong>
			</div>
			
			var hulpondersteuning = '';

			if (faq.length > 0) {

				var faqlist = Object.keys(faq[0]).map((key) => <li key={key}><a target='_blank' rel='noopener noreferrer' href={faq[0][key]['url']}>{faq[0][key]['title']}</a></li>);

				hulpondersteuning =
					<div className="faq">
						<h2>Hulp en ondersteuning</h2>
						<ul>{faqlist}</ul>
					</div>
			};

			if (docs.length > 0) {

				var doclist = Object.keys(docs[0]).map((key) => <li key={key}><a target='_blank' rel='noopener noreferrer' href={docs[0][key]['download_url']}>{docs[0][key]['title']}</a></li>);

				var documenten =
					<div className="faq">
						<h2>Documenten</h2>
						<ul>{doclist}</ul>
					</div>
			};

			navigation = (
				<div className="navigation">
					<div className="left">{buttonprevious}&nbsp;</div>
					<div className="label">
						<h3 className="name font-bold">Installatie {this.state.installatie_selected} :</h3>
						<h3 className="detail font-bold">{Parser(description)}</h3>
					</div>
					<div className="right">&nbsp;{buttonnext}</div>
					<div className="clear"></div>

				</div>
			)
		}

		if (this.state.view_selected === 'detail') {

			return (
				<div>
					<h1>Welkom terug directeur {this.props.fullname}</h1>

					{navigation}

					{product.flatMap(({ data, key }) =>
						data.map(({ id, qty, productname, garantee_date }) => {
							const formattedGaranteeDate =
								parseFloat(garantee_date?.slice(-4)) < 2000
									? 'niet beschikbaar'
									: garantee_date;

							return (
								<div className="installatie" key={id}>
									<h2>{qty === 1 ? key : plurallabels[key]}</h2>
									{qty} X {productname} <br />
									Productgarantie tot: <strong> {formattedGaranteeDate}</strong> <br />
								</div>
							);
						})
					)}

					{installatiegarantie}
					{hulpondersteuning}
					{documenten}

					<button className="button" onClick={() => this.navigate(0)}>Overzicht</button>
				</div>
			);

		} else {

			if (number < 1) {
				return (
					<>
						<h1>Welkom terug directeurs {this.props.fullname}</h1>
						<p>Er zijn (nog) geen installaties gekoppeld aan dit account.</p>
					</>
				);
			}
			let installation_id = Object.keys(this.props.InstallatieData)[this.state.installatie_selected-1]
			let selectedInstallation = this.props.InstallatieData[installation_id]

			let userImage = (selectedInstallation.user_photo) ?  selectedInstallation.user_photo :  demoImage;
			const divStyle = {
				backgroundImage: 'url(' + userImage + ')',
				backgroundSize: 'cover',
				backgroundPosition: 'center'
			};

			return (
				<div>
					<div className="image">
						<div id="installatie_img" style={divStyle}></div>
						<UserPhotoUpload
							installation={installation_id}
							isLoading={this.state.isLoading}
							setCache = {this.props.setCache}
							getCache = {this.props.getCache}
							clearCache = {this.props.clearCache}
							fetchInstallaties = {this.props.fetchInstallaties}
							setLoading = {this.props.setLoading}
						/>
					</div>
					<h1>Welkom terug directeur {this.props.fullname}</h1>

					<h3 className="title">U heeft {number} {(number > 1) ? 'installaties' : 'installatie' } van De Zonnefabriek.</h3>

					{navigation}
					<ul className="hidedisc">
						<li><button className='button2' to='/installaties' onClick={this.toggleView}>Systeemgegevens en - garantie</button></li>
					</ul>

				</div>
			);
		}
	}
}

class LoadingDiv extends React.Component {
	render() {
		return (
			<>
				<h1>Welkom terug directeur {this.props.fullname}</h1>
				<div className="LoadingDiv">
					<img src={loadingGif} alt="Loading" />
				</div>
			</>
		)
	}
}

export default InstallatieDetail
