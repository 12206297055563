import React from 'react'
import loadingGif from '../assets/images/loading.gif'

class ProcessRequestPayout extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
            isLoading: true,
            data: {}
        }

	}

	handleInputChange(event) {
		this.props.handleChange(event);
	}

	processPayout() {

		let username = localStorage.getItem('user_name')
        let token = localStorage.getItem('token')
		const {totalamount, totaluitgekeerd} = this.props;
		let totaltegoed = totalamount - totaluitgekeerd;

        let url = process.env.REACT_APP_API_URL + '?function=requestPayout&user=' + username + '&token=' +  token ;

		var call = url +
			'&iban_number=' + this.props.data.iban_number +
			'&iban_name=' + this.props.data.iban_name +
			'&payout_amount=' + totaltegoed
			;

		fetch(call)
			.then(response => response.json())
	        .then(data =>
	        	this.setState({
                	data: data,
					isLoading: false
                })
            ).then(data =>
	            this.processGTMevent()
            ).then(data =>
	            this.props.clearCache('getProspects', false)
            );

	}

	componentDidMount() {
		this.processPayout();
	}

	processGTMevent() {
		if (this?.state?.data?.Error) {
			this.props.GTMevent('vriend_aangemeld_existing_lead', 'Vrienden', 'Vriend aangemeld', 'Existing lead');
		} else {
			this.props.GTMevent('vriend_aangemeld_success', 'Vrienden', 'Vriend aangemeld', 'Success');
		}
	}

	resetRequestPayout = () => {
		this.props.resetRequestPayout();
	}

	render() {

		const {isLoading} = this.state;

		if (isLoading) {
            return <LoadingDiv />;
        }


		if (this?.state?.data?.Error) {
			return <div className="error">
						<p>{this.state.data.Error}</p>
						<p><button className="backlink" onClick={() => this.resetRequestPayout() }>Overzicht</button></p>
			</div>
		} else {
			return <div>
						<p>Dank voor uw aanvraag, we maken de vergoeding zo snel mogelijk over.</p>
						<p><button className="backlink" onClick={() => this.resetRequestPayout() }>Overzicht</button></p>
			</div>
		}

	}
}

class LoadingDiv extends React.Component {
    render() {
        return (
            <div className="LoadingDiv">
                <img src={loadingGif} alt="Loading" />
            </div>
        )
    }
}

export default ProcessRequestPayout
