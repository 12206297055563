import React from 'react'
import './Chart.css';
import loadingGif from '../assets/images/loading.gif'
import Carousel from 'react-grid-carousel'
import { VictoryBar, VictoryChart, VictoryLine, VictoryTooltip, VictoryContainer, VictoryVoronoiContainer } from 'victory'


const precacheCalls = [
    //	'&timeframe=day&period=-1',
    //	'&timeframe=month&period=0',
    //	'&timeframe=total&period=0'
];

const kwhPriceDefault = 0.19;

const errors = {
    '': 'Helaas kunnen wij op dit moment uw gegevens niet ophalen. Neem contact op met service@zonnefabriek.nl, zodat we u verder kunnen helpen.',
    'Unknown-error': 'Helaas kunnen wij op dit moment uw gegevens niet ophalen. Neem contact op met service@zonnefabriek.nl, zodat we u verder kunnen helpen.',
    'Pending': 'Om uw gegevens op te halen heeft De Zonnefabriek uw toestemming nodig. Wij hebben een mail gestuurd naar --email-- waarmee u uw toestemming kunt verlenen.',
    'Revoked': 'Om uw gegevens op te halen heeft De Zonnefabriek uw toestemming nodig. U heeft hiervoor eerder toestemming voor gegeven, maar deze lijkt ingetrokken te zijn. Wij hebben u een mail gestuurd naar --email-- om deze toestemming opnieuw te geven.',
    'Rejected': 'Om uw gegevens op te halen heeft De Zonnefabriek uw toestemming nodig. Wij hebben een mail gestuurd waarmee u uw toestemming kunt verlenen, maar deze is geweigerd. Wij hebben u een mail gestuurd naar --email-- om deze toestemming opnieuw te geven.',
    'No-data': 'Helaas kunnen wij op dit moment uw gegevens niet ophalen. Kijk op https://www.zonnefabriek.nl/faq/monitoring-uitgevallen/ of neem contact op met service@zonnefabriek.nl, zodat we u verder kunnen helpen.',
    'no-privileges': 'Om uw gegevens op te halen heeft De Zonnefabriek uw toestemming nodig. Helaas blijkt dat het emailadres dat in ons systeem bekend is (--email--), niet als  \'system owner\' is ingesteld bij SMA. Neem contact op met service@zonnefabriek.nl zodat we dit goed kunnen instellen.',
    'Not enough userdata to connect': 'Er is nog geen installatie gekoppeld aan dit account, waarschijnlijk omdat dit een nieuwe installatie betreft. Heeft u het idee dat deze installatie reeds aangemeld zou moeten zijn, neem dan contact op met service@zonnefabriek.nl.',
    'Not Found': 'Om uw gegevens op te halen heeft De Zonnefabriek uw toestemming nodig. Hiervoor proberen wij een autorisatie-mail te sturen, maar het lijkt erop dat uw account nog niet goed gekoppeld is in EnnexOS. <br /><br />Dit kunt u oplossen door in te loggen op onderstaand adres: <br /><br /> <a href="https://ennexos.sunnyportal.com/login">https://ennexos.sunnyportal.com/login</a>.<br /><br /> Wanneer u dit heeft gedaan kunt dit scherm (knop rechtsboven) verversen om de autorisatie-mail alsnog te versturen.'
};

class MonthlyChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoading: false,
            timeframe: 'day',
            cached: null,
            url: this.getUrl() + '&timeframe=day&period=0',
            isCached: false,
            preCacheLoaded: false,
            systemId: ''
        };
    }

    preloadCache() {

        let url = this.getUrl();

        precacheCalls.map((item) =>
            fetch(url + item)
                .then(response => response.json())
                .then(data => this.setState({
                    cache: [...this.state.cache, { 'url': url + item, 'response': data.Result.usage }]
                }))
        );

    }

    updateChart() {
        var cachedResponse = this.props.getCache(this.state.url);

        if (cachedResponse) {
            this.setState({
                data: cachedResponse.yield,
            });
        } else {
            this.fetchUrl(this.state.url);
        }
    }

    getUrl = () => {

        const username = localStorage.getItem('user_name')
        const token = localStorage.getItem('token')

        const url = process.env.REACT_APP_API_URL + '?function=getYield&user=' + username + '&token=' + token;

        return url;
    }

    fetchUrl(url) {

        this.setState({
            data: [],
            isLoading: true
        });

        fetch(url)
            .then((response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error('Response from API not ok')
                }
            })
            .then((data) => {
                if (data.Error) {
                    this.setState({
                        error: data.Error,
                        emailsent: (data.Sent) ? data.Sent : 'Onbekend',
                        cache: false,
                        isLoading: false
                    })
                } else if (!data.Result) {
                    this.setState({
                        error: 'No-data',
                        cache: false,
                        isLoading: false
                    })
                } else {
                    this.setState({
                        data: (data.Result.yield === null) ? [] : data.Result.yield,
                        yieldData: (data.Result.yield === null) ? [] : data.Result.yield,
                        cache: { 'url': url, 'response': data.Result },
                        isLoading: false
                    })
                }
            })
            .then((data) => {
                if (this.state.cache) {
                    this.props.setCache(this.state.cache, this.state.timeframe, null)
                }
            }).catch((error) => {
                this.setState({
                    isLoading: false
                });
            });

    }

    componentDidMount() {
        this.preloadCache();
        this.updateChart();
    }

    render() {
        const { data, isLoading } = this.state;

        if (isLoading) {
            return <LoadingDiv />;
        }

        if (!Array.isArray(data)) {
            const element = [];
            Object.entries(data).map((data, index) => {
                return element.push(
                    <Carousel.Item key={index} className="carouselCs">
                        <BuildChart
                            state={this.state}
                            systemId={data[0]}
                            index={index}
                            setCache={this.props.setCache}
                            getCache={this.props.getCache}
                            api_type={this.props.api_type}
                        />
                    </Carousel.Item>
                );

            });
            return <Carousel
                cols={1}
                rows={1}
                gap={1}
                loop
            >
                {element}
            </Carousel>;
        }

        return <BuildChart
            state={this.state}
            systemId=""
            index=""
            setCache={this.props.setCache}
            getCache={this.props.getCache}
            api_type={this.props.api_type}
        />
    }
}

class BuildChart extends React.Component {
    _isMounted = false

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoading: false,
            timeframe: 'day',
            period: 0,
            cache: [],
            prices: {},
            summary: [],
            cached: null,
            url: this.getUrl() + '&timeframe=day&period=0',
            isCached: false,
            preCacheLoaded: false,
            isActiveDay: true,
            isActiveMonth: false,
            totalYield: 0,
            totalYieldEuro: 0,
            currentYield: 0,
            currentYieldLabel: 'Vandaag',
            currentChartLabel: 'Vandaag',
            systemId: ''
        };

        // This binding is necessary to make `this` work in the callback
        this.deductPeriod = this.deductPeriod.bind(this);
        this.addPeriod = this.addPeriod.bind(this);

    }

    preloadCache() {

        let url = this.getUrl();

        precacheCalls.map((item) =>
            fetch(url + item)
                .then(response => response.json())
                .then(data => this.setState({
                    cache: [...this.state.cache, { 'url': url + item, 'response': data.Result.usage }]
                }))
        );

    }

    setCache(url, systemId, response, timeframe) {
        let cacheIdentifier = url + '/' + systemId
        if (systemId) {
            cacheIdentifier = cacheIdentifier + '/' + systemId
        }
        
        let data = {
            url: cacheIdentifier,
            response: response
        }
        this.props.setCache(data, timeframe, null)
    }

    getCache(url, systemId) {
        let cacheIdentifier = url + '/' + systemId
        if (systemId) {
            cacheIdentifier = cacheIdentifier + '/' + systemId
        }

        return this.props.getCache(cacheIdentifier)
    }

    updateChart() {
        if (!this._isMounted) {
            return
        }

        // var cachedResponse = false
        var cachedResponse = this.getCache(this.state.url, this.state.systemId)
        // var cachedResponse = this.props.getCache(this.state.url);

        if (cachedResponse) {
            this.updateSummary(cachedResponse)
            this.setState({
                data: cachedResponse.yield,
                yieldData: cachedResponse.yield,
                // summary: this.updateSummary(cachedResponse),

            });
        } else {
            this.fetchUrl(this.state.url);
        }
        //this.fetchYearTotal();
        this.fetchTotal();
    }
    getUrl = () => {

        let username = localStorage.getItem('user_name')
        let token = localStorage.getItem('token')

        let url = process.env.REACT_APP_API_URL + '?function=getYield&user=' + username + '&token=' + token;

        return url;
    }

    fetchUrl(url) {
        this.setState({
            data: [],
            isLoading: true
        });

        fetch(url)
            .then((response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error('Response from API not ok')
                }
            })
            .then((data) => {
                if (data.Error) {
                    this.setState({
                        error: data.Error,
                        emailsent: (data.Sent) ? data.Sent : 'Onbekend',
                        cache: false,
                        isLoading: false
                    })
                } else if (!data.Result) {
                    this.setState({
                        error: 'No-data',
                        cache: false,
                        isLoading: false
                    })
                } else {
                    if (this._isMounted) {
                        this.setState({
                            data: (data.Result.yield === null) ? [] : data.Result.yield,
                            yieldData: (data.Result.yield === null) ? [] : data.Result.yield,
                            cache: { 'url': url, 'response': data.Result },
                            // summary: this.updateSummary(data.Result),
                            prices: data.Result.prices,
                            isLoading: false
                        })
                        this.updateSummary(data.Result)
                    }

                    this.setCache(url, this.state.systemId, data.Result, this.state.timeframe)
                    // this.props.setCache({ 'url': url, 'response': data.Result }, this.state.timeframe, null)
                }
            })
            .then((data) => {
                if (this.state.cache) {
                    // this.props.setCache(this.state.cache, this.state.timeframe, null)
                }
            }).catch((error) => {
                this.setState({
                    isLoading: false
                });
            });

    }

    fetchStateData(data) {
        if (!data) {
            return {}
        }
        
        let multipleSystems = false
        let summaryResults = '';
        let summaryEntries = Object.entries(data.summary)
        if (summaryEntries[0][0] === 'total_kwh') {
            // Check if there is only one system response
            summaryResults = data.summary
        } else {
            multipleSystems = true
            if (!this.state.systemId) {
                summaryResults = summaryEntries[0][1];
            } else {
                if (!Array.isArray(data.summary)) {
                    summaryEntries.map((item) => {
                        if (([item[0], ""].includes(this.state.systemId))) {
                            summaryResults = item[1]
                        }
                        return summaryResults
                    });
                }
            }
        }

        /*let mapping = {
            'total_kwh': 'totalYield',
            'total_euro': 'totalYieldEuro',
            'current_year_total_kwh': 'totalYearYield',
            'current_year_total_euro': 'totalYearYieldEuro',
        }
        
        Object.entries(data.summary).map((item) => {
            if (mapping[item[0]]) {
                let key = mapping[item[0]]
                stateData[key] = item[1]
            }
        })*/

        return {
            summary: data.summary,
            summaryData: data.summary,
            multipleSystems: multipleSystems,
            prices: data?.prices,
            totalYield: summaryResults?.total_kwh,
            totalYieldEuro: summaryResults?.total_euro,
            totalYearYield: summaryResults?.current_year_total_kwh,
            totalYearYieldEuro: summaryResults?.current_year_total_euro,
            isLoading: false
        }
    }

    fetchTotal(useCache = true) {

        this.setState({ isLoading: true });
        let url = this.getUrl() + `&timeframe=total`;

        var cachedResponse = this.props.getCache(url);
        if (cachedResponse && useCache) {
            let stateData = this.fetchStateData(cachedResponse)
            this.setState(stateData)
        } else {
            fetch(url)
                .then(response => response.json())
                .then(data => {
                    if (data.Result) {
                        let stateData = this.fetchStateData(data.Result)
                        stateData['cache'] = { 'url': url, 'response': data.Result }
                        this.setState(stateData)
                    }
                })
                .then((data) => {
                    if (this.state.cache) {
                        this.props.setCache(this.state.cache, 'total', null)
                    }
                });

        }

    }

    fetchYearTotal() {

        this.setState({ isLoading: true });
        let url = this.getUrl() + `&timeframe=year&period=0`;

        var cachedResponse = this.props.getCache(url);

        if (cachedResponse) {
            if (!Array.isArray(cachedResponse.yield)) {
                Object.entries(cachedResponse.yield).map((item) => {
                    if (([item[0], ""].includes(this.state.systemId)) && Array.isArray(item[1])) {
                        cachedResponse.yield = item[1]
                    }
                    return cachedResponse.yield
                });
            }
            if (!Array.isArray(cachedResponse.summary)) {
                Object.entries(cachedResponse.summary).map((item) => {
                    if (([item[0], ""].includes(this.state.systemId))) {
                        cachedResponse.summary = item[1]
                    }
                    return cachedResponse.summary
                });
            }
            this.setState({
                prices: cachedResponse?.prices,
                totalYearYield: cachedResponse?.summary?.current_year_total_kwh,
                totalYearYieldEuro: cachedResponse?.summary?.current_year_total_euro,
                // totalYearYield: this.sumTotals(cachedResponse.yield),
                // totalYearYieldEuro: this.sumTotals(cachedResponse.yield, cachedResponse.prices, 2022)
                isLoading: false
            });
        } else {
            fetch(url)
                .then(response => response.json())
                .then(data => {
                    let summaryResults = '';
                    if (!Array.isArray(data.Result.summary)) {
                        Object.entries(data.Result.summary).map((item) => {
                            if (([item[0], ""].includes(this.state.systemId))) {
                                summaryResults = item[1]
                                this.setState({ systemId: item[0] });
                            }
                            return summaryResults
                        });
                    }

                    this.setState({
                        cache: { 'url': url, 'response': data.Result },
                        prices: data.Result?.prices,
                        totalYearYield: summaryResults !== '' ? summaryResults?.current_year_total_kwh : data.Result?.summary?.current_year_total_kwh,
                        totalYearYieldEuro: summaryResults !== '' ? summaryResults?.current_year_total_euro : data.Result?.summary?.current_year_total_euro,
                        // totalYearYield: this.sumTotals(yieldResults !== '' ? yieldResults : data.Result.yield),
                        // totalYearYieldEuro: this.sumTotals(yieldResults !== '' ? yieldResults : data.Result.yield, data.Result.prices, 2022)
                        isLoading: false
                    })
                })
                .then((data) => {
                    if (this.state.cache) {
                        this.props.setCache(this.state.cache, 'year', null)
                    }
                });

        }

    }
    sumTotals(kwh, prices = null, year = null) {
        let sum = 0;
        let array = kwh;

        if (prices) {
            if (year) {
                if (typeof array === "object" && (array !== null)) {
                    Object.keys(array).map((row) => (
                        sum += parseFloat(array[row][1]) * this.getKwhPrice(prices, parseFloat(year))
                    ));
                }
            } else {
                if (typeof array === "object" && (array !== null)) {
                    Object.keys(array).map((row) => (
                        sum += parseFloat(array[row][1]) * this.getKwhPrice(prices, parseFloat(array[row][0]))
                    ));
                }
            }
        } else {
            if (typeof array === "object" && (array !== null)) {
                Object.keys(array).map((row) => (
                    sum += parseFloat(array[row][1])
                ));
            }
        }

        return sum;
    }

    getKwhPrice = (prices = null, year = 0) => {
        let handle = 'kwh_price_' + year;
        let kwhPrice = 0;

        if (handle in prices) {
            kwhPrice = prices[handle];
        } else if ('kwh_price' in prices) {
            kwhPrice = prices['kwh_price'];
        } else {
            kwhPrice = kwhPriceDefault;
        }

        return kwhPrice;
    }

    updateSummary(response) {
        let currentYieldLabel = '';
        if (!Array.isArray(response.yield)) {
            Object.entries(response.yield).map((item) => {
                if (([item[0], ""].includes(this.state.systemId)) && Array.isArray(item[1])) {
                    response.yield = item[1]
                }
                return response.yield
            });
        }
        if (this.state.timeframe === 'day') {
            if (this.state.period === 0) {
                currentYieldLabel = 'Vandaag';

            } else if (this.state.period === -1) {
                currentYieldLabel = 'Gisteren';
            } else {
                const today = new Date();
                today.setDate(today.getDate() + this.state.period);
                currentYieldLabel = today.toLocaleDateString();
            }

            let yieldTotals = this.sumTotals(response.yield)
            this.setState({
                //if there is more than 500kWh generated, we assume that zeversolar is sending us watthour
                currentYield: (yieldTotals > 500) ? (yieldTotals / 1000) : yieldTotals,
                currentYieldUnit: 'kWh',
                currentYieldLabel: currentYieldLabel,
                currentChartLabel: currentYieldLabel
            });
        }

        if (this.state.timeframe === 'month') {
            if (this.state.period === 0) {
                currentYieldLabel = 'Deze maand';

            } else if (this.state.period < 0) {
                const today = new Date();
                today.setMonth(today.getMonth() + (this.state.period))
                currentYieldLabel = today.toLocaleDateString('nl-NL', { month: 'long' });
                currentYieldLabel = currentYieldLabel.charAt(0).toUpperCase() + currentYieldLabel.slice(1)
            }

            this.setState({
                currentYield: this.sumTotals(response.yield),
                currentYieldUnit: 'kWh',
                currentYieldLabel: currentYieldLabel,
                currentChartLabel: currentYieldLabel
            });
        }

        if ((this.state.timeframe === 'year') && (this.state.period < 0)) {
            const today = new Date();
            today.setDate(today.getDate() + (this.state.period * 365));
            currentYieldLabel = today.toLocaleDateString('nl-NL', { year: 'numeric' });

            this.setState({
                currentYield: this.sumTotals(response.yield),
                currentYieldUnit: 'kWh',
                currentYieldLabel: currentYieldLabel
            });
        }

        /*if (this.state.timeframe === 'year') {

            const today = new Date();
            today.setDate(today.getDate() + (this.state.period * 365));

            this.setState({
                currentChartLabel: today.toLocaleDateString('nl-NL', { year: 'numeric' })
            });
        }

        if (this.state.timeframe === 'total') {
            this.setState({
                currentChartLabel: 'Totaal'
            });
        }*/

    }

    changeTimeframe = (timeframe) => {

        let url = this.getUrl();

        this.setState({
            period: 0,
            timeframe: timeframe,
            url: url + "&timeframe=" + timeframe + "&period=0"
        }, () => {
            this.updateChart();
        });
    }

    deductPeriod() {
        let url = this.getUrl();
        this.setState({
            period: this.state.period - 1,
            url: url + "&timeframe=" + this.state.timeframe + "&period=" + (this.state.period - 1)
        }, () => {
            this.updateChart();
        });
    }

    addPeriod() {
        let url = this.getUrl();
        this.setState({
            period: this.state.period + 1,
            url: url + "&timeframe=" + this.state.timeframe + "&period=" + (this.state.period + 1)
        }, () => {
            this.updateChart();
        });
    }

    checkChartExists = (i, chart) => {
        let found = false;
        chart.forEach(
            row => {
                if (row.x === i) {
                    found = true;
                }
            }
        );
        return found;
    }
    handleUpdateSystemId = (systemId) => {
        this.setState({ systemId: systemId });
    };

    componentDidMount() {
        this._isMounted = true
        this.handleUpdateSystemId(this.props.systemId);
        this.updateChart();
        // this.preloadCache();
    }

    render() {
        let data = this.state.data;

        if (this.state.isLoading) {
            return <LoadingDiv />;
        }

        /*let yieldData = []
        let dataEntries = Object.entries(this.state.data)
        if (dataEntries.length > 0) {
            if (dataEntries[0][0] === 'total_kwh') {
                // Check if there is only one system response
                yieldData = this.state.data
            } else {
                if (!this.state.systemId) {
                    yieldData = dataEntries[0][1];
                } else {
                    if (!Array.isArray(this.state.data)) {
                        dataEntries.map((item) => {
                            if (([item[0], ""].includes(this.state.systemId))) {
                                yieldData = item[1]
                            }
                        });
                    }
                }
            }

            // data = yieldData
        }*/

        if (!Array.isArray(this.state.data)) {
            Object.entries(this.state.data).map((item) => {
                if ((item[0] === this.state.systemId) && Array.isArray(item[1])) {
                    data = item[1]
                }
                return data
            });
        }
        let index = this.props.index;
        let chartresult = '';
        let summary = '';
        let timeframenavigation = '';
        let periodnavigation = '';
        if (data) {
            let theDay, theValue, x, y, label, currentDate
            let chartData = []
            let buttonnext, buttonprevious;
            let dates = [];

            if (data.length > 0) {

                data.forEach(day => {

                    day[0] = day[0] === "jan" ? 0 : day[0]
                    day[0] = day[0] === "feb" ? 1 : day[0]
                    day[0] = day[0] === "mrt" ? 2 : day[0]
                    day[0] = day[0] === "apr" ? 3 : day[0]
                    day[0] = day[0] === "mei" ? 4 : day[0]
                    day[0] = day[0] === "jun" ? 5 : day[0]
                    day[0] = day[0] === "jul" ? 6 : day[0]
                    day[0] = day[0] === "aug" ? 7 : day[0]
                    day[0] = day[0] === "sep" ? 8 : day[0]
                    day[0] = day[0] === "okt" ? 9 : day[0]
                    day[0] = day[0] === "nov" ? 10 : day[0]
                    day[0] = day[0] === "dec" ? 11 : day[0]

                    theDay = parseInt(day[0])
                    theValue = parseFloat(day[1])

                    var d = new Date();

                    if (this.state.timeframe === "day" || this.state.timeframe == null) {
                        currentDate = new Date(d.getFullYear(), d.getMonth(), d.getDate() + this.state.period, theDay, 0, 0)
                        x = currentDate.getHours();
                        y = theValue;
                        label = currentDate.getHours() + ' uur: ' + theValue.toFixed(2) + ' kW';
                    }

                    if (this.state.timeframe === "month") {
                        currentDate = new Date(d.getFullYear(), d.getMonth() + this.state.period, theDay, 0, 0, 0);
                        x = currentDate.getDate();
                        y = theValue;
                        label = currentDate.getDate() + ' ' + currentDate.toLocaleString('default', { month: 'short' }) + ': ' + theValue.toFixed(2) + ' kWh';

                    }

                    if (this.state.timeframe === "year") {
                        currentDate = new Date(d.getFullYear() + this.state.period, theDay, 1, 0, 0, 0)
                        x = currentDate.getMonth() + 1;
                        let monthname = currentDate.toLocaleString('nl', { month: 'short' });
                        x = monthname.charAt(0).toUpperCase() + monthname.substr(1, 2);
                        y = theValue;
                        label = x + ': ' + theValue.toFixed(2) + ' kWh';
                    }

                    if (this.state.timeframe === "total") {
                        currentDate = new Date(theDay, 1, 1, 0, 0, 0);
                        x = currentDate.getFullYear().toString();
                        y = theValue;
                        label = currentDate.getFullYear().toString() + ': ' + theValue.toFixed(0) + ' kWh';
                    }

                    dates.push(theDay)

                    chartData.push({ x: x, y: y, label: label, eventKey: Math.random() });

                });


                var i = 1;
                var d = new Date();
                var chartMax = {
                    "day": 24,
                    "month": new Date(d.getFullYear(), d.getMonth() + this.state.period, 0).getDate(),
                    "year": 12,
                    "total": data.length
                };

                if (this.state.timeframe !== "total") {

                    for (i; i < chartMax[this.state.timeframe]; i++) {
                        if (!this.checkChartExists(i, chartData)) {
                            chartData.push({ x: (i), y: null, label: null, eventKey: Math.random() });
                        }
                    }

                }


                if (this.state.timeframe === 'day') {
                    chartresult = (
                        <VictoryChart
                            containerComponent={
                                <VictoryVoronoiContainer
                                    style={{
                                        touchAction: "auto"
                                    }}
                                    labelComponent={
                                        <VictoryTooltip dy={-7} constrainToVisibleArea />
                                    }
                                />
                            }
                            domainPadding={{ x: 25 }}
                        >
                            <VictoryLine
                                style={{
                                    data: {
                                        stroke: "#225db3",
                                        strokeWidth: 5,
                                        strokeLinecap: "round"
                                    }
                                }}
                                data={chartData}
                                labelComponent={<VictoryTooltip
                                    style={{
                                        fontSize: "12px",
                                        fontFamily: "Gotham Light"
                                    }}
                                    flyoutPadding={{
                                        top: 5, bottom: 5, left: 10, right: 10
                                    }}
                                    flyoutStyle={{
                                        stroke: "#225db3",
                                        fill: "#fff"
                                    }}
                                />}
                            />
                        </VictoryChart>
                    )

                } else {

                    var barWidths = {
                        "month": 8,
                        "year": 15,
                        "total": 20
                    }

                    chartresult = (
                        <VictoryChart
                            containerComponent={
                                <VictoryContainer
                                    style={{
                                        touchAction: "auto"
                                    }}
                                />
                            }
                            domainPadding={{ x: 25 }}
                        >
                            <VictoryBar
                                barWidth={barWidths[this.state.timeframe]}
                                style={{ data: { fill: "#225db3" } }}
                                data={chartData}
                                labelComponent={<VictoryTooltip
                                    style={{
                                        fontSize: "12px",
                                        fontFamily: "Gotham Light"
                                    }}
                                    flyoutPadding={{
                                        top: 5, bottom: 5, left: 10, right: 10
                                    }}
                                    flyoutStyle={{
                                        stroke: "#225db3",
                                        fill: "#fff"
                                    }}
                                />}
                            />
                        </VictoryChart>
                    )
                }
            } else {
                chartresult = <span
                    dangerouslySetInnerHTML={{ __html: 'Deze grafiek kan helaas niet worden weergegeven. <br /><br />Kijk op <a href="https://www.zonnefabriek.nl/faq/monitoring-uitgevallen/" target="_blank">https://www.zonnefabriek.nl/faq/monitoring-uitgevallen/</a> als u hier data had verwacht.' }} />;
            }

            let checkLabelformultipleYields = '';

            if (index !== '') {
                checkLabelformultipleYields = (
                    <li>
                        <label>Systeem {index + 1}</label>
                    </li>
                );
            }

            let summaryResults = {}
            if (this.state.multipleSystems) {
                summaryResults = this.state.summaryData[this.state.systemId]
            } else {
                summaryResults = this.state.summaryData
            }
            let summaryRows = {
                totalYield: summaryResults?.total_kwh,
                totalYieldEuro: summaryResults?.total_euro,
                totalYearYield: summaryResults?.current_year_total_kwh,
                totalYearYieldEuro: summaryResults?.current_year_total_euro,
            }

            summary = (
                <div className="summary">
                    <ul>
                        {checkLabelformultipleYields}
                        <li>
                            <label>{this.state.currentYieldLabel}</label>
                            {this.state.currentYield > 0 ? Math.round(this.state.currentYield) : '-'} {this.state.currentYieldUnit}
                        </li>
                        <li>
                            <label>Dit jaar</label>
                            {summaryRows.totalYearYield > 0 ? Math.round(summaryRows.totalYearYield) : '-'} kWh / €{summaryRows.totalYearYieldEuro > 0 ? Math.round(summaryRows.totalYearYieldEuro) : '-'},-
                        </li>
                        <li>
                            <label>Totaal</label>
                            {summaryRows.totalYield > 0 ? Math.round(summaryRows.totalYield) : '-'} kWh / €{summaryRows.totalYieldEuro > 0 ? Math.round(summaryRows.totalYieldEuro) : '-'},-
                        </li>
                    </ul>
                </div>
            )

            timeframenavigation = (
                <div className="timeframenavigation">
                    <ul>
                        <li>
                            <button className={` ${(this.state.timeframe === "day") ? "active" : ""}`}
                                onClick={() => { this.changeTimeframe('day'); this.handleUpdateSystemId(this.props.systemId) }}>DAG
                            </button>
                        </li>
                        <li>
                            <button className={` ${(this.state.timeframe === "month") ? "active" : ""}`}
                                onClick={() => { this.changeTimeframe('month'); this.handleUpdateSystemId(this.props.systemId) }}>MAAND
                            </button>
                        </li>
                        <li>
                            <button className={` ${(this.state.timeframe === "year") ? "active" : ""}`}
                                onClick={() => { this.changeTimeframe('year'); this.handleUpdateSystemId(this.props.systemId) }}>JAAR
                            </button>
                        </li>
                        <li>
                            <button className={` ${(this.state.timeframe === "total") ? "active" : ""}`}
                                onClick={() => { this.changeTimeframe('total'); this.handleUpdateSystemId(this.props.systemId) }}>TOTAAL
                            </button>
                        </li>
                    </ul>
                </div>
            )

            if (this.state.period < 0) {
                buttonnext = <button className='button right' onClick={this.addPeriod}>&gt;</button>
            }

            if (this.state.timeframe !== 'total' && !(this.props.api_type === 'sma' && this.state.timeframe === 'month' && this.state.period < 0)) {
                buttonprevious = <button className='button left' onClick={this.deductPeriod}>&lt;</button>
            }

            periodnavigation = (
                <div className="navigation">
                    <div className="left">
                        {buttonprevious}&nbsp;
                    </div>
                    <div className="label">
                        {this.state.currentChartLabel}
                    </div>
                    <div className="right">
                        &nbsp;{buttonnext}
                    </div>
                </div>
            )

        }

        let content = '';


        if (this.state.error && this.state.error in errors) {
            content = (
                <div className="chartDiv">
                    {!(this.state.error in errors) ?
                        'Er is een onbekende fout opgetreden, neem contact op met service@zonnefabriek.nl als het probleem zich blijft voordoen. (' + this.state.error + ')'
                        :
                        <span
                            dangerouslySetInnerHTML={{ __html: errors[this.state.error].replace(/--email--/g, this.state.emailsent) }} />
                    }
                </div>
            )
        } else {
            content = (
                <div className="chartDiv">
                    {summary}
                    {timeframenavigation}
                    {periodnavigation}
                    {chartresult}
                </div>
            )

        }

        return (
            <div>
                {content}
            </div>
        )

    }
}


class LoadingDiv extends React.Component {
    render() {
        return (
            <div className="LoadingDiv">
                <img src={loadingGif} alt="Loading" />
            </div>
        )
    }
}

class Chart extends React.Component {
    render() {
        return (
            <div>
                <MonthlyChart
                    setCache={this.props.setCache}
                    getCache={this.props.getCache}
                    api_type={this.props.api_type}
                    className="usageChart"
                />
            </div>
        )
    }
}

export default Chart
