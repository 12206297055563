import React from 'react'
import { NavLink } from 'react-router-dom'

class Account extends React.Component { 
	
	render() {
		return <NavLink exact={true} activeClassName='active' className='account' to='/account'><span></span>Account
		<span className='read'></span></NavLink>
	}
}

export default Account
