import React from 'react'

class RequestPayout extends React.Component {
	constructor(props) {
	    super(props);

	    this.handleInputChange = this.handleInputChange.bind(this);
	}

	handleInputChange(event) {
		this.props.handleChange(event);
	}

	resetRequestPayout = () => {
		this.props.resetRequestPayout();
		this.props.changePage('overview');
	}

	render() {

		return (
			<div className="dakcheck">
				<form onSubmit={this.handleSubmit} className="stepVriend">
					<div className="inputRow">
						<label>
							IBAN rekeningnummer<br />
						</label>

						<div className="inputField">
							<input name="iban_number" required={true} className={this.props.errors['iban_number'] ? "error" : ""} type="text" value={this.props.data['iban_number']} onChange={this.handleInputChange} />
						</div>
					</div>
					<div className="inputRow">
						<label>
							Naam rekeninghouder<br />
						</label>

						<div className="inputField">
							<input name="iban_name" required={true} className={this.props.errors['iban_name'] ? "error" : ""} type="text" value={this.props.data['iban_name']} onChange={this.handleInputChange} />
						</div>
					</div>
				</form>
				<button className="button" onClick={() => this.props.changePage('processRequestPayout') }>Verzenden</button>
				<button href="#" className="backlink" onClick={() =>  this.resetRequestPayout()}>Terug</button>
			</div>
		)
	}
}


export default RequestPayout
