import React from 'react'
import TagManager from 'react-gtm-module'
import Main from './components/Main'
import logo from './components/assets/images/logo.svg';
import moment from "moment";
import Notification from "./components/Notification/Notification";

import './components/assets/css/App.css'
import './components/assets/css/Mobile.css'
import './components/assets/css/Tablet.css'
import './components/assets/css/Desktop.css'


class App extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			user_name: null,
			api_type: null,
			app_notice: null,
			data: {},
			isLoggedin: false,
			error: null,
			response: null,
			errors: { username: false, password: false },
			key: Math.random(),
			pathname: '',
			isLoading: true,
			isShowNotification: JSON.parse(localStorage.getItem('isShowNotification')) ?? true,
			// ZPWA-83
			nextCheckingTokenTime: localStorage.getItem('nextCheckingTime'),
			cachedUrl: []
		};
		this.GTMinit();
	}
	componentDidMount() {
		const user_name = localStorage.getItem('user_name');
		const token = localStorage.getItem('token');

		this.checkToken(user_name, token);

		if (this.state.isLoggedin !== true) {
			this.GTMpageview('/');
		}
	}

	GTMinit = () => {
		TagManager.initialize({
			gtmId: process.env.REACT_APP_GTM_TRACKING_CODE,
		});

	};

	GTMpageview = (location = null, title = null) => {

		let currentpathname = (location) ? location : window.location.pathname + window.location.search;

		if (currentpathname !== this.state.pathname) {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: 'pageview',
				page: {
					url: currentpathname,
					title: title
				}
			});
			this.setState({ pathname: currentpathname });
		}

	}

	GTMevent = (event = 'Unknown', category = 'Unknown', action = 'Unknown', label = 'Unknown', value = 'Unknown') => {

		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: event,
			eventProps: {
				category: category,
				action: action,
				label: label,
				value: value
			}
		});

	}

	clearCache = (type, refresh = true) => {

		const cachedStorage = JSON.parse(localStorage.getItem('cache'));
		let newCache = '';

		if (typeof type != "undefined" && cachedStorage != null) {
			newCache = cachedStorage.filter(call => call.type !== type);
			localStorage.setItem('cache', JSON.stringify(newCache));
		} else {
			localStorage.removeItem('cache');
		}

		if (refresh) {
			this.setState({ key: Math.random() });
		}

	}

	clearCacheByUrl = (url) => {
		const cachedStorage = JSON.parse(localStorage.getItem('cache'));
		const newCache = cachedStorage.filter(call => call.url !== url);
		localStorage.setItem('cache', JSON.stringify(newCache));
	}

	setCache = (cache, timeframe = '', lifetime = 2592000) => {
		//standard lifetime = 60*60*24*30 = 30 days

		if (Object.keys(cache).length === 0) { return; }

		let timestamp = Math.floor(Date.now());

		const endOfHour = moment().endOf('hour').format('x');
		const endOfDay = moment().endOf('day').format('x');
		const endOfMonth = moment().endOf('month').format('x');
		const endOfYear = moment().endOf('year').format('x');

		switch (timeframe) {
			case 'day':
				cache['lifetime'] = endOfHour;
				cache['expire_at'] = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(endOfHour);
				break;
			case 'month':
			case 'total':
				cache['lifetime'] = endOfDay;
				cache['expire_at'] = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(endOfDay);
				break;
			case 'year':
			case 'vrienden':
				cache['lifetime'] = endOfMonth;
				cache['expire_at'] = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(endOfMonth);
				break;
			case 'installations':
				cache['lifetime'] = endOfYear;
				cache['expire_at'] = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(endOfYear);
				break;
			default:
				cache['lifetime'] = timestamp + lifetime;
				cache['expire_at'] = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(timestamp + lifetime);
		}

		let url = cache['url'].split("?")[1];
		const urlParams = new URLSearchParams(url);
		cache['type'] = urlParams.get('function');

		var localCache = JSON.parse(localStorage.getItem('cache'))
		var newCache = (localCache) ? [...localCache, cache] : [cache];

		localStorage.setItem('cache', JSON.stringify(newCache));

	}

	getCache = (url) => {

		var cachedStorage = JSON.parse(localStorage.getItem('cache'));

		let timestamp = Math.floor(Date.now() / 1000);

		if (cachedStorage) {
			var cached = cachedStorage.filter(call => call.url === url);
			if (cached.length > 0) {
				if (cached[0].lifetime > timestamp) {
					return cached[0].response;
				} else {
					this.clearCacheByUrl(url);
				}
			}
		}

		return false;
	}

	handleLogout = () => {
		// ZPWA-83
		localStorage.removeItem('nextCheckingTime');
		localStorage.removeItem('user_name');
		localStorage.removeItem('token');

		this.setState({
			user_name: '',
			isLoggedin: false,
			errors: {},
			error: 'U bent uitgelogd.'
		});
	}

	handleChange = (event) => {

		this.setState({
			data: {
				...this.state.data,
				[event.target.name]: event.target.value
			},
			errors: {
				...this.state.errors,
				[event.target.name]: false
			}
		});
	}

	handleSubmit = async e => {
		e.preventDefault();
		// make api call

		if (!this.state.data.username) {
			this.setState({
				errors: {
					...this.state.errors,
					username: true
				}
			});

			return false;
		}

		if (!this.state.data.password) {
			this.setState({
				errors: {
					...this.state.errors,
					password: true
				}
			});

			return false;
		}

		const url = process.env.REACT_APP_API_URL + '?function=getToken&user=' + this.state.data.username + '&pass=' + this.state.data.password;

		await fetch(url)
			.then(response => response.json())
			.then(data =>
				this.setState({
					response: data
				})
			)
			.then(data =>
				this.processLogin()
			);

		this.setState({
			isLoading: false,
		})
	};

	createCheckingNextTime = () => {
		const now = new Date();
		// Add 1 minutes in milliseconds
		const futureTime = new Date(now.getTime() + 1 * 60 * 1000);

		return Math.floor(futureTime.getTime() / 1000);
	};

	validateCheckingTime = (checkingTime) => {
		// Current timestamp in seconds
		const now = Math.floor(Date.now() / 1000);

		const user_name = localStorage.getItem('user_name');
		const token = localStorage.getItem('token');
		const urlCheck = process.env.REACT_APP_API_URL + '?function=checkToken&user=' + user_name + '&token=' + token;

		// Recheck cache url to prevent call same url when child components did mount
		const cachedUrl = this.state.cachedUrl.filter((url) => {
			return `${urlCheck}&timestamp=${checkingTime}` === url
		});

		return now < checkingTime && cachedUrl.length > 0;
	}


	checkToken = (user_name = '', token = '') => {
		// ZPWA-83
		let nextCheckingTime = localStorage.getItem('nextCheckingTime');

		nextCheckingTime = nextCheckingTime ? parseInt(nextCheckingTime, 10) : this.state.nextCheckingTokenTime;

		if (!user_name || !token || !nextCheckingTime) {
			this.setState({
				isLoading: false
			});
			this.handleLogout();
			return;
		}

		const url = process.env.REACT_APP_API_URL + '?function=checkToken&user=' + user_name + '&token=' + token;

		if (this.validateCheckingTime(nextCheckingTime)) {
			this.setState({
				isLoading: false,
				isLoggedin: true,
			});

			return;
		} else {
			// Set cached url
			this.setState({
				cachedUrl: [`${url}&timestamp=${nextCheckingTime}`],
			});
		}

		fetch(url)
			.then(response => response.json())
			.then(data => {
				if (data.Success === true) {
					if (data.Result !== true) {
						this.handleLogout();
						return;
					}

					// ZPWA-83
					const nextCheckTime = this.createCheckingNextTime();

					this.setState({
						isLoggedin: true,
						user_name: user_name,
						fullname: data.Fullname,
						address: data.Address,
						email: data.Email,
						api_type: data.ApiType,
						app_notice: data.app_notice,
						isLoading: false,
						nextCheckingTokenTime: nextCheckTime
					});

					localStorage.setItem('nextCheckingTime', nextCheckTime);

					this.setState({
						cachedUrl: [`${url}&timestamp=${nextCheckTime}`],
					});
				}
			})
			.then(data => {
				this.setState({
					isLoading: false
				})
			});
	}

	processLogin = response => {
		if (!this.state.response.Success) {
			this.setState({
				error: 'Incorrecte gebruikernaam/wachtwoord combinatie.'
			});

			return;
		}

		localStorage.setItem('token', this.state.response.Result);
		localStorage.setItem('user_name', this.state.data.username);
		// ZPWA-83
		const nextCheckTime = this.createCheckingNextTime();
		localStorage.setItem('nextCheckingTime', nextCheckTime);

		this.setState({
			user_name: this.state.data.username,
			fullname: this.state.response.Fullname,
			address: this.state.response.Address,
			email: this.state.response.Email,
			api_type: this.state.response.ApiType,
			data: {},
			error: null,
			token: this.state.response.Result,
			response: null,
			isLoggedin: true,
			nextCheckingTokenTime: nextCheckTime,
			isShowNotification: true
		});
	}

	render() {

		const { isLoading } = this.state;

		let notification = '';

		if (this.state.isShowNotification) {
			notification = (
				<Notification type="info" message={this.state.app_notice} />
			);
		}

		if (this.state.isLoggedin) {
			return (
				<div className="wrapper">
					{notification}
					<Main
						handleLogout={this.handleLogout}
						isLoggedin={this.state.isLoggedin}
						user_name={this.state.user_name}
						fullname={this.state.fullname}
						address={this.state.address}
						email={this.state.email}
						api_type={this.state.api_type}
						token={this.state.token}
						setCache={this.setCache}
						getCache={this.getCache}
						checkToken={this.checkToken}
						clearCache={this.clearCache}
						clearCacheNew={this.clearCacheNew}
						key={this.state.key}
						GTMpageview={this.GTMpageview}
						GTMevent={this.GTMevent}
					/>
				</div>
			)
		}

		if (isLoading) {
			return <LoadingDiv />;
		}

		return (
			<div className="loginscreen">
				<div className="logo">
					<img src={logo} alt="Zonnefabriek" />
				</div>

				<form className="loginform" onSubmit={this.handleSubmit}>

					<div className="error">{this.state.error}</div>


					<input name="username" className={this.state.errors['username'] ? "error" : ""} type="text" onChange={this.handleChange} placeholder="Uw gebruikersnaam: (Vb. 1234AB1)" />
					<input name="password" className={this.state.errors['password'] ? "error" : ""} type="password" onChange={this.handleChange} placeholder="Uw wachtwoord" />

					<button type="submit">Login</button>

				</form>
				<div className="meta">
					<a href="https://www.zonnefabriek.nl/onze-service/mijn-zonnefabriek/wachtwoord-vergeten/">Wachtwoord vergeten</a>
				</div>
			</div>
		);
	}
}

class LoadingDiv extends React.Component {
	render() {
		return (
			<div className="loginscreen">
				<div className="logo">
					<img src={logo} alt="Zonnefabriek" />
				</div>
				<div className="loadinghome">Bezig met laden...</div>
			</div>
		)
	}
}

export default App
