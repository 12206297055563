import React from 'react'
import icon_dakrichting from '../assets/images/icon_dakrichting.png'


class Step3 extends React.Component { 
	constructor(props) {
	    super(props);
	    this.handleInputChange = this.handleInputChange.bind(this);
	}
	
	handleInputChange(event) {
		this.props.handleChange(event);
	}

	render() {
		
		var dakrichtingSelected = (this.props.data['dakrichting']) ? this.props.data['dakrichting'] : 'Z';
		return (
			<form className="step3">
				<p>In welke richting is uw dak gericht?</p>
				
				<img src={icon_dakrichting} alt="dakrichting" />

				
				<div className="inputRow">
					<div className="inputField">
						<select name="dakrichting" value={dakrichtingSelected} onChange={this.handleInputChange} >
							<option value="N">Noorden</option>
							<option value="NO">Noordoosten</option>
							<option value="O">Oosten</option>
							<option value="ZO">Zuidoosten</option>
							<option value="Z">Zuiden</option>
							<option value="ZW">Zuidwesten</option>
							<option value="W">Westen</option>
							<option value="NW">Noordwesten</option>
						</select>
					</div>
				</div>
			</form>	
		)
	}
}


export default Step3
