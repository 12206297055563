import React from 'react'
import loadingGif from '../assets/images/loading.gif'

const START_YEAR = 2021

class UpdatePrices extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: {
				'kwh_price': ''
			},
			errors: {
				'kwh_price': false
			},
			errornotice: ''
		};
	}

	componentDidMount() {
        let user_name = localStorage.getItem('user_name');
        let token = localStorage.getItem('token');

        this.getPrices(user_name, token);
    }

	getPrices = (user_name, token) => {
        const url = process.env.REACT_APP_API_URL + '?function=getPrices&user=' + user_name + '&token=' + token;

		fetch(url)
		.then(response => response.json())
		.then(data => {
			if (data?.Success === true && data?.Result?.prices) {
				const value = {}
				Object.keys(data.Result.prices).map((key) => (
					value[key] = data.Result.prices[key]
				))

				this.setState({
					data: value
				})
			}
		});
    }

	handleInputChange = (event) => {
		const value = {...this.state.data, [event.target.name]: event.target.value}

		this.setState({
			data: value
		});

	}

	handleSubmit = () => {

		this.setState({isLoading: true});

		const kwh_prices = this.state.data ?? {}

		if (Object.keys(kwh_prices).length < 1) {
			this.setState({
				errors: {
					'kwh_price': true
				},
				errornotice: '',
				isLoading: false
			})
			return;
		}
		const username = localStorage.getItem('user_name')
		const token = localStorage.getItem('token')
		let url = process.env.REACT_APP_API_URL + '?function=updatePrices&user=' + username + '&token=' +  token;
		const defaultValue = this.state.data['kwh_price'];

		Object.keys(kwh_prices).map(function(key) {
			let value = kwh_prices[key]

			if (isNaN(parseFloat(value, 10))) {
				value = defaultValue;
			}

			return url+=`&${key}=${value}`;
		});

		fetch(url)
		.then(response => response.json())
		.then((data) => {
			if (data.Success) {
				this.setState({
					errors: {
						'kwh_price': false
					},
					errornotice: '',
					isLoading: false
				});
			} else {
				this.setState({
					errors: {
						'kwh_price': true
					},
					errornotice: '',
					isLoading: false
				});
			}
		})
	}

	getPrice = (year) => {
        const price = !isNaN(parseFloat(this.state.data[`kwh_price_${year}`], 10)) 
			? this.state.data[`kwh_price_${year}`] 
			: this.state.data['kwh_price']

        return !isNaN(parseFloat(price, 10)) ? parseFloat(price, 10) : ''
    }

	render() {
		const isLoading = this.state.isLoading;

		if (isLoading) {
			return <LoadingDiv />;
		}
		
		const currentYear = new Date().getFullYear();
		const years = [];

		for (let year = START_YEAR; year <= currentYear; year++) {
			years.push(year);
		}

		return (
			<div>
				<div className="updatePrices">
					<h2>Uw energieprijzen</h2>
					<form onSubmit={this.handleSubmit} className="updatePrices">
						<div className="error">{this.state.errornotice}</div>
						{years.map((year, index) => (
							<div className="inputRow" key={index}>
								<label>{year}: <span>€</span></label>  <br />
								<div className="inputField">
									<input 
										name={`kwh_price_${year}`} 
										type="number"
										value={this.getPrice(year)}
										onChange={this.handleInputChange} />
								</div>
							</div>
						))}
					</form>

					<button className="button" onClick={() => this.handleSubmit() }>Wijzigen</button>
				</div>
			</div>
		)
   	}
}

class LoadingDiv extends React.Component {
	render() {
		return (
			<div className="LoadingDiv">
				<img src={loadingGif} alt="Loading" />
			</div>
		)
	}
}

export default UpdatePrices
