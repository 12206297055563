import React from 'react'
import icon_plat from '../assets/images/icon_plat.png'
import icon_schuin from '../assets/images/icon_schuin.png'
import icon_heelschuin from '../assets/images/icon_heelschuin.png'

class Step2 extends React.Component { 
	constructor(props) {
	    super(props);
	    this.handleInputChange = this.handleInputChange.bind(this);
	}
		
	handleInputChange(event) {
		this.props.handleChange(event);
	}
	
	render() {
		return (			
			<form className="step2">
				<p>Kies het soort dak</p>
				
				<div className="inputRow">
					<div className="inputField">
						<input name="daktype" type="radio" value="0" checked={this.props.data['daktype'] === '0'} onChange={this.handleInputChange} />
					</div>
					<label>
						Plat dak<br />
						<small>(0°)</small>
					</label>
					<img src={icon_plat} alt="plat" />
				</div>
				<div className="inputRow">
					<div className="inputField">
						<input name="daktype" type="radio" value="20" checked={this.props.data['daktype'] === '20'} onChange={this.handleInputChange} />
					</div>
					<label>
						Schuin dak<br />
						<small>(Tussen 0° en 60°)</small>
					</label>
					<img src={icon_schuin} alt="schuin" />
				</div>
				<div className="inputRow">
					<div className="inputField">
						<input name="daktype" type="radio" value="60" checked={this.props.data['daktype'] === '60'} onChange={this.handleInputChange} />
					</div>
					<label>
						Heel schuin dak<br />
						<small>(Meer dan 60°)</small>
					</label>
					<img src={icon_heelschuin} alt="heelschuin" />
				</div>
			</form>
		)
	}
}


export default Step2
