import React from 'react'
import Logout from './Account/Logout'
import ChangePassword from './Account/ChangePassword'
import UpdatePrices from './Account/UpdatePrices'


class Account extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 'overview',
        };
    }

    componentDidMount() {
        let user_name = localStorage.getItem('user_name');
        let token = localStorage.getItem('token');

        this.props.checkToken(user_name, token);
    }

    changePage = (page) => {
        this.setState({page: page});
    }


    render() {
        if (this.state.page === 'successPage') {

            return (

                <div className="contentWrapper">
                    <div className="content">
                        <h1>Uw account</h1>
                        <div className="success">Uw wachtwoord is veranderd.</div>
                        <button href="#" className="backlink" onClick={() => this.changePage('overview')}>Terug</button>

                    </div>
                </div>

            )

        } else if (this.state.page === 'changePassword') {

            return (

                <div className="contentWrapper">
                    <div className="content">
                        <h1>Uw account</h1>
                        <ChangePassword
                            changePage={this.changePage}
                        />
                    </div>
                </div>

            );

        } else {

            return (

                <div className="contentWrapper">
                    <div className="content">
                        <h1>Uw account</h1>
                        <div className="changePassword">
                            <div>

                                <h2>Uw gegevens</h2>
                                <strong>Naam:</strong><br/>
                                {this.props.fullname}<br/><br/>

                                <strong>Email:</strong><br/>
                                {this.props.email}<br/><br/>

                                <strong>Adres:</strong><br/>
                                {this.props.address}<br/><br/>

                                <strong>Wachtwoord:</strong><br/>
                                ********<br/>

                                <button className="button" onClick={() => this.changePage('changePassword')}>Wijzig
                                    wachtwoord
                                </button>
                            </div>
                        </div>

                        <UpdatePrices />

                        <Logout
                            handleLogout={this.props.handleLogout}
                            user_name={this.props.user_name}
                            GTMevent={this.props.GTMevent}
                            GTMpageview={this.props.GTMpageview}
                        />
                    </div>
                </div>

            );

        }
    }
}

export default Account
