import React from 'react'
import Leads from './Vrienden/Leads'
import AddFriend from './Vrienden/AddFriend'
import ProcessFriend from './Vrienden/ProcessFriend'
import PullToRefresh from 'react-simple-pull-to-refresh';
import RequestPayout from "./Vrienden/RequestPayout";
import ProcessRequestPayout from "./Vrienden/ProcessRequestPayout";
import Friend from "./Vrienden/Friend";


class Vrienden extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            page: 'overview',
            current_reward: 0,
            payment_status: '',
            totalamount: 0,
            totaluitgekeerd: 0,
            data: [],
            inputData: {
                'vriendnaam': '',
                'vriendemail': '',
                'vriendtelefoon': ''
            },
            inputErrors: {
                'vriendnaam': false,
                'vriendemail': false,
                'vriendtelefoon': false
            }
        };
    }

    componentDidMount() {
        let user_name = localStorage.getItem('user_name');
        let token = localStorage.getItem('token');

        this.props.checkToken(user_name, token);
    }

    handleChange = (event) => {

        this.setState({
            inputData: {
                ...this.state.inputData,
                [event.target.name]: event.target.value
            }
        });

    }

    changePage = (page) => {

        var regName = new RegExp('^[a-zA-Z .,&()]{2,30}$');
        // eslint-disable-next-line no-useless-escape
        var regEmail = new RegExp('^[a-zA-Z0-9_+\.-]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+.+[a-zA-Z0-9]$');

        const regIban = new RegExp('^(?:(?:IT|SM)\\d{2}[A-Z]\\d{22}|CY\\d{2}[A-Z]\\d{23}|NL\\d{2}[A-Z]{4}\\d{10}|LV\\d{2}[A-Z]{4}\\d{13}|(?:BG|BH|GB|IE)\\d{2}[A-Z]{4}\\d{14}|GI\\d{2}[A-Z]{4}\\d{15}|RO\\d{2}[A-Z]{4}\\d{16}|KW\\d{2}[A-Z]{4}\\d{22}|MT\\d{2}[A-Z]{4}\\d{23}|NO\\d{13}|(?:DK|FI|GL|FO)\\d{16}|MK\\d{17}|(?:AT|EE|KZ|LU|XK)\\d{18}|(?:BA|HR|LI|CH|CR)\\d{19}|(?:GE|DE|LT|ME|RS)\\d{20}|IL\\d{21}|(?:AD|CZ|ES|MD|SA)\\d{22}|PT\\d{23}|(?:BE|IS)\\d{24}|(?:FR|MR|MC)\\d{25}|(?:AL|DO|LB|PL)\\d{26}|(?:AZ|HU)\\d{27}|(?:GR|MU)\\d{28})$');

        if (page === 'processFriend' && (!regName.test(this.state.inputData['vriendnaam']) || !regEmail.test(this.state.inputData['vriendemail']))) {

            this.setState({
                inputErrors: {
                    ...this.state.inputErrors,
                    vriendnaam: !regName.test(this.state.inputData['vriendnaam']),
                    vriendemail: !regEmail.test(this.state.inputData['vriendemail'])
                }
            });

            return false;
        }

        if (page === 'processRequestPayout' && (!regIban.test(this.state.inputData['iban_number']) || !regName.test(this.state.inputData['iban_name']))) {

            this.setState({
                inputErrors: {
                    ...this.state.inputErrors,
                    iban_number: !regIban.test(this.state.inputData['iban_number']),
                    iban_name: !regName.test(this.state.inputData['iban_name'])
                }
            });

            return false;
        }

        this.setState({page: page});
    }


    resetAddFriend = () => {
        this.setState({
            page: 'overview',
            inputData: {
                'vriendnaam': '',
                'vriendemail': '',
                'vriendtelefoon': ''
            },
            inputErrors: {
                'vriendnaam': false,
                'vriendemail': false,
                'vriendtelefoon': false
            }
        });
    }

    resetRequestPayout = () => {
        this.setState({
            page: 'overview',
            inputData: {
                'iban_number': '',
                'iban_name': ''
            },
            inputErrors: {
                'iban_number': false,
                'iban_name': false
            }
        });
        this.setState({
            totalamount: 0,
            totaluitgekeerd: 0
        });
    }

    renderPage(page) {

        if (page === 'addFriend') {
            return <AddFriend
                changePage={this.changePage}
                handleChange={this.handleChange}
                data={this.state.inputData}
                errors={this.state.inputErrors}
            />
        }

        if (page === 'processFriend') {
            return <ProcessFriend
                changePage={this.changePage}
                handleChange={this.handleChange}
                data={this.state.inputData}
                resetAddFriend={this.resetAddFriend}
                clearCache={this.props.clearCache}
                GTMevent={this.props.GTMevent}
            />
        }

        if (page === 'overview') {
            return <Leads
                isLoading={this.state.isLoading}
                user_name={this.props.user_name}
                token={this.props.token}
                setCache={this.props.setCache}
                getCache={this.props.getCache}
                fetchFriends={this.fetchFriends}
                friends={this.state.friends}
                toCurrency={this.toCurrency}
                current_reward={this.state.current_reward}
                payment_status={this.state.payment_status}
                changePage={this.changePage}
                GTMevent={this.props.GTMevent}
                totalamount={this.state.totalamount}
                totaluitgekeerd={this.state.totaluitgekeerd}
            />
        }

        if (page === 'requestPayout') {
            return <RequestPayout
                changePage={this.changePage}
                handleChange={this.handleChange}
                resetRequestPayout={this.resetRequestPayout}
                data={this.state.inputData}
                errors={this.state.inputErrors}
            />
        }

        if (page === 'processRequestPayout') {
            return <ProcessRequestPayout
                changePage={this.changePage}
                handleChange={this.handleChange}
                resetRequestPayout={this.resetRequestPayout}
                data={this.state.inputData}
                clearCache={this.props.clearCache}
                GTMevent={this.props.GTMevent}
                totalamount={this.state.totalamount}
                totaluitgekeerd={this.state.totaluitgekeerd}
            />
        }
    }

    fetchFriends = () => {

        let username = localStorage.getItem('user_name')
        let token = localStorage.getItem('token')
        let url = process.env.REACT_APP_API_URL + '?function=getProspects&user=' + username + '&token=' + token;

        var cachedResponse = this.props.getCache(url);

        if (cachedResponse) {
            this.setState({
                data: cachedResponse,
                current_reward: cachedResponse.current_reward,
                payment_status: cachedResponse.payment_status,
                friends: this.processFriends(cachedResponse.prospects),
                isLoading: false
            })
        } else {
            fetch(url)
                .then(response => response.json())
                .then(data =>
                    this.setState({
                        current_reward: data.Result.current_reward,
                        payment_status: data.Result.payment_status,
                        data: {'url': url, 'response': data.Result},
                        friends: this.processFriends(data.Result.prospects)
                    }))
                .then(data =>
                    this.setState({
                        isLoading: false
                    }))
                .then(data => {
                    if (this.state.data) {
                        this.props.setCache(this.state.data, 'vrienden', null)
                    }
                }).catch((error) => {
                    this.setState({
                        isLoading: false
                    });
                });
        }
    }

    isArray(o) {
        return Object.prototype.toString.call(o) === '[object Array]';
    }

    toCurrency(amount = 0) {
        return '€' + parseFloat(amount).toFixed(0) + ',-';
    }

    processAmount(status, prospect_reward = 0) {
        let amount = 0;
        let message = '';

        if (status === 'GO') {
            amount = prospect_reward;
            message = this.toCurrency(prospect_reward) + ' verdiend';

            this.setState((prevState, props) => ({
                totalamount: prevState.totalamount + parseFloat(amount)
            }));
        } else if (status === 'GO (uitbetaald)') {
            amount = prospect_reward;
            message = this.toCurrency(prospect_reward) + ' verdiend en uitbetaald';

            this.setState((prevState, props) => ({
                totalamount: prevState.totalamount + parseFloat(amount),
                totaluitgekeerd: prevState.totaluitgekeerd + parseFloat(amount)
            }));

        } else {
            message = '(Nog) geen akkoord'
        }

        return message;
    }

    processFriends(friends) {

        let array = [];
        let akkoord = [];
        let uitbetaald = [];
        let aangedragen = [];
        if (!this.isArray(friends)) {
            return false;
        }

        friends.forEach((item, key) => {
            this.processAmount(item?.status, item?.prospect_reward)
            switch (item?.status) {
                case 'GO':
                    akkoord.push(
                        <Friend key={key} friend={item} toCurrency={this.toCurrency} />
                    );
                    break;
                case 'GO (uitbetaald)':
                    uitbetaald.push(
                        <Friend key={key} friend={item} toCurrency={this.toCurrency} />
                    );
                    break;
                default:
                    aangedragen.push(
                        <Friend key={key} friend={item} toCurrency={this.toCurrency} />
                    );
                    break;
            }
        });
        array = {
            "akkoord": akkoord,
            "uitbetaald": uitbetaald,
            "aangedragen": aangedragen,
            "count": friends?.length
        }

        return array;
    }

    handleRefresh = () => {
        new Promise((resolve, reject) => {
            this.props.clearCache('getProspects');
            resolve("Promise resolved successfully");
        });
    }

    render() {
        return (

            <div className="contentWrapper vrienden">

                <PullToRefresh onRefresh={this.handleRefresh}>

                    <div className="content">

                        <h1>Vrienden</h1>

                        {this.renderPage(this.state.page)}

                    </div>

                </PullToRefresh>

            </div>

        );
    }
}

export default Vrienden
