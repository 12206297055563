import React from 'react';

class Friend extends React.Component {

    render() {
        const item = this.props.friend;
        return(
            <div className='lead' key={item?.klantnummer}>
                <div className='leadname'>
                    <span className="aanhef">{item?.aanhef}</span> {item?.accountname} ({this.props.toCurrency(item?.prospect_reward)})
                </div>
            </div>
        );
    }
}

export default Friend;
