import React from 'react'
import icon_change from "../assets/images/icon_change.jpg";

class UserPhotoUpload extends React.Component {

    state = {
        uploading: false,
        image: null
    }

    onChange = e => {
        const files = Array.from(e.target.files)
        this.props.setLoading(true)

        const formData = new FormData()

        files.forEach((file, i) => {
            formData.append('image', file)
        })

        formData.append('installation_id', this.props.installation);

        let username = localStorage.getItem('user_name')
        let token = localStorage.getItem('token')

        fetch(process.env.REACT_APP_API_URL + '?function=uploadInstallationImage&user=' + username + '&token=' +  token , {
            method: 'POST',
            body: formData
        })
        .then(res => res.json())
        .then(image => {
            this.setState({
                image: image.Result
            })
        })
        .then(cache =>
        	this.props.clearCache()
        )
		.then(cache =>
        	this.props.fetchInstallaties('getInstallaties')
        )
        .then(cache =>
			this.props.setLoading(false)
        )
    }

    render() {
        return (
            <div className='buttons fadein'>
                <div className='button'>
                    <label htmlFor="image" className="small">
                        <img src={icon_change} alt=""/>
                    </label>
                    <input type='file' id='image'  onChange={this.onChange}  accept='image/*'/>
                </div>
            </div>
        );
    }
}

export default UserPhotoUpload
