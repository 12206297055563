import React from 'react'

class AddFriend extends React.Component { 
	constructor(props) {
	    super(props);
	    
	    this.handleInputChange = this.handleInputChange.bind(this);
	}
	  
	handleInputChange(event) {
		this.props.handleChange(event);
	}
	
	render() {
		return (
			<div className="dakcheck">
			<form onSubmit={this.handleSubmit} className="stepVriend">
				<p>Geef de gegevens van de eigenaar van dit dak op, en wij nemen zo snel mogelijk contact met hem/haar op!</p>
				
				<div className="inputRow">
					<label>
						Naam<br />
					</label>
					
					<div className="inputField">
						<input name="vriendnaam" className={this.props.errors['vriendnaam'] ? "error" : ""} type="text" value={this.props.data['vriendnaam']} onChange={this.handleInputChange} />
					</div>
				</div>
				<div className="inputRow">
					<label>
						Email<br />
					</label>
					
					<div className="inputField">
						<input name="vriendemail" className={this.props.errors['vriendemail'] ? "error" : ""} type="text" value={this.props.data['vriendemail']} onChange={this.handleInputChange} /> 
					</div>
				</div>
				<div className="inputRow">
					<label>
						Telefoon<br />
					</label>
					
					<div className="inputField">
						<input name="vriendtelefoon" className={this.props.errors['vriendtelefoon'] ? "error" : ""} type="text" value={this.props.data['vriendtelefoon']} onChange={this.handleInputChange} /> 
					</div>
				</div>
			</form>
			<button className="button" onClick={() => this.props.changePage('processFriend') }>Verzenden</button>
			<button href="#" className="backlink" onClick={() => this.props.changePage('overview') }>Terug</button>
			
			</div>
		)
	}
}


export default AddFriend
